import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import OptionControlBoxM from "./OptionControlBoxM";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import VideoListModal from "./VideoListModal";
import * as ServerApi from "../../constants/ServerApiM";
import * as MyUtil from "../../constants/MyUtil";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";
import { IoIosCloseCircle } from "react-icons/io";
import AlertModal from "react-modal";
import NewSaveButton from "./NewSaveButton";
import { useSwipeable } from "react-swipeable";
import InfoModal from "./InfoModal";
import AWS from "aws-sdk";
import Config from "../../constants/Config";

AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_AWSAccessKeyId}`,
  secretAccessKey: `${process.env.REACT_APP_AWSSecretKey}`,
});

const myBucket = new AWS.S3({
  params: { Bucket: Config.S3_BUCKET },
  region: Config.S3_REGION,
});

const GlobalStyle = createGlobalStyle`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 20px;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .react-calendar__navigation button {
    color: #09348a;
    font-weight: bold;
    background: none;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .react-calendar__navigation button:hover,
  .react-calendar__navigation button:focus {
    background-color: transparent;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button:hover,
  .react-calendar__navigation__next-button:hover,
  .react-calendar__navigation__prev-button:focus,
  .react-calendar__navigation__next-button:focus {
    background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button {
    top: 40px;
    left: 73px;
    color: #999 !important;
  }

  .react-calendar__navigation__next-button {
    top: 40px;
    right: 73px;
    color: #999 !important;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    font-size: 18px;
    font-weight: bold;
    pointer-events: none;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: transparent;
    border-radius: 6px;
  }

  .react-calendar__tile--now {
    background: #ffff76;
    border-radius: 6px;
    font-weight: bold;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
    border-radius: 6px;
    font-weight: bold;
  }

  .react-calendar__tile--active {
    background: #09348a;
    border-radius: 6px;
    font-weight: bold;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
    color: white;
  }
`;

const CalendarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const CalendarWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;

  &.visible {
    display: block;
    z-index: 999;
  }

  .react-calendar {
    border-radius: 15px;
    padding-bottom: 20px;
    position: relative;
  }

  .react-calendar__navigation {
    background: none;
    color: #000;
  }

  .react-calendar__navigation button:disabled {
    background: none;
    color: #000;
  }

  .react-calendar__tile--active {
    background-color: #09348a;
    border-radius: 10px;
  }

  .react-calendar__tile--now {
    background-color: rgba(9, 52, 138, 0.2);
    border-radius: 10px;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #09348a;
  }

  .react-calendar__month-view__weekdays {
    /* margin-top: 30px; */
    color: #09348a;
  }

  .react-calendar__month-view__days {
    margin-bottom: 50px;
  }

  .calendarSaveBtn {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    background: #09348a;
    border: none;
    border-radius: 15px;
    color: #ffffff;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  box-sizing: border-box;
  background: none;
  color: #09348a;
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;

  &:active {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }

  &:hover {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }
`;

const Items = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #09348a;
  height: 100px;
  position: relative;
  color: #09348a;
  font-size: 30px;
`;

const ItemsTitle = styled.div`
  font-size: 0.9rem;
  margin-top: 30px;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 7px;

  &:checked {
    background-color: #09348a;
    border-color: #09348a;
    //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 20 8l-1.41-1.41z"/></svg>');
    //background-repeat: no-repeat;
    //background-position: center;
  }
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  left: calc(50% - 5px);
  transform: translate(-50%, 50%);
  scale: 0.9;
`;

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: #fff;
  width: 80vw;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 15px 15px 0 0;
`;

function ExerciseSetup({
  videoData,
  proNo,
  onCopy,
  onDelete,
  onSetting,
  isSelected,
  selectedItems,
  isEditMode,
  inFolder,
  foldNo,
}: any) {
  const history = useHistory();
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  // 캘린더 요일을 영문 표기
  const customWeekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [managerPhone, setManagerPhone] = useState("");

  const [videoFile, setVideoFile] = useState<any>(null);
  const [imgFile1, setImgFile1] = useState<any>(null);
  const [imgFile2, setImgFile2] = useState<any>(null);
  const [imgFile3, setImgFile3] = useState<any>(null);
  const [imgFile4, setImgFile4] = useState<any>(null);
  const [videoUrl, setVideoUrl] = useState<any>("");

  const [DELVideoUrl1, setDelVideoUrl1] = useState<any>("");
  const [DELimgUrl1, setDelImgUrl1] = useState<any>("");
  const [DELimgUrl2, setDelImgUrl2] = useState<any>("");
  const [DELimgUrl3, setDelImgUrl3] = useState<any>("");
  const [DELimgUrl4, setDelImgUrl4] = useState<any>("");
  const [ADDvideoFile, setADDVideoFile] = useState<any>(null);

  // 클릭 이벤트를 이용하여 달력 창을 닫기 위한 로직
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarWrapRef.current &&
        !calendarWrapRef.current.contains(event.target as Node) &&
        isCalendarOpen
      ) {
        setIsCalendarOpen(false); // 모달 외부 클릭 시 모달 닫기
      }
    };

    // 컴포넌트가 마운트되면 document 클릭 이벤트 리스너를 추가
    document.addEventListener("click", handleClickOutside);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCalendarOpen]);

  // viewDate와 selectedDate가 변경될 때마다 캘린더를 업데이트
  useEffect(() => {
    if (selectedDate) {
      setViewDate(selectedDate);
    }
  }, [selectedDate]);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  // 캘린더 아이콘을 클릭하면 달력 창을 토글
  const toggleCalendarIcon = () => {
    console.log("pro_no", proNo);
    console.log("center_no1", MLoginInfo.MLoginInfo.center_no);
    setIsCalendarOpen(!isCalendarOpen);
  };

  // 캘린더 모달을 닫는 함수
  const closeCalendarModal = () => {
    setIsCalendarOpen(false);
  };

  // 캘린더 스와이프 핸들러
  const handleSwipe = useCallback(
    (direction: string) => {
      const newDate = new Date(activeStartDate);
      if (direction === "LEFT") {
        newDate.setMonth(newDate.getMonth() + 1);
      } else if (direction === "RIGHT") {
        newDate.setMonth(newDate.getMonth() - 1);
      }
      setActiveStartDate(newDate);
      setViewDate(newDate);
    },
    [activeStartDate]
  );

  // 캘린더 스와이프 핸들러
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    trackMouse: true,
    touchEventOptions: { passive: false },
  });

  // 날짜가 변경될 때 호출되며, 선택한 날짜를 업데이트
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setViewDate(date);
    setActiveStartDate(date);
    console.log("Selected Date:", date);
  };

  const handleSaveConfirm = async () => {
    if (!MLoginInfo || !MLoginInfo.MLoginInfo || !proNo) {
      setTimeout(() => {
        setDnStatus("error");
        setIsCalendarOpen(false);
      }, 2000);
      return;
    }

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    console.log("formattedDate", formattedDate);
    try {
      setDnStatus("loading");
      const response = await ServerApi.m_app_pro_center_i({
        center_no: MLoginInfo.MLoginInfo.center_no,
        c_day: formattedDate,
        pro_no: proNo,
      });
      console.log("m_app_pro_center_i", response, proNo);
      if (response && response.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            setIsCalendarOpen(false);
            setDnStatus("ready");
          }, 1000);
        }, 1000);
      } else {
        setTimeout(() => {
          setDnStatus("error");
          setTimeout(() => {
            setIsCalendarOpen(false);
            setDnStatus("ready");
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setDnStatus("error");
        setTimeout(() => {
          setIsCalendarOpen(false);
          setDnStatus("ready");
        }, 1000);
      }, 2000);
    }
  };

  const handleItemClick = () => {
    history.push({
      pathname: "/VideoPlay",
      state: { pro_no: videoData.pro_no },
    });
  };

  const handleListClick = () => {
    setIsModalOpen(true);
  };

  const handleSettingClick = () => {
    if (inFolder && foldNo) {
      onSetting(proNo, foldNo);
    } else {
      onSetting(proNo);
    }
  };

  const handleChatClick = () => {
    setIsCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  // 댓글 저장 함수
  const handleSaveComment = async () => {
    if (!commentContent.trim()) {
      setModalMessage("댓글 내용을 입력해주세요.");
      setModalIsOpen(true);
      return;
    }

    try {
      const response = await ServerApi.m_mng_pro_reply_i({
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no: videoData.pro_no,
        contents: commentContent,
      });

      if (response && response.rsp_code === "100") {
        setIsCommentModalOpen(false);
        setCommentContent("");
        setModalMessage("댓글이 성공적으로 등록되었습니다.");
        setModalIsOpen(true);
      } else {
        setModalMessage("댓글등록에 실패했습니다.");
        setModalIsOpen(true);
        setIsCommentModalOpen(false);
        setCommentContent("");
      }
    } catch (error) {
      console.error("댓글 등록 중 오류 발생:", error);
      setModalMessage("댓글 등록 중 오류가 발생했습니다.");
      setModalIsOpen(true);
      setIsCommentModalOpen(false);
      setCommentContent("");
    }
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  const handleCopyClick = () => {
    onCopy(proNo);
  };

  const handleEditClick = async () => {
    console.log("handleEditClick");
    setIsInfoModal(true);

    // 버튼 클릭 시 데이터 호출
    let result = await ServerApi.m_mng_pro_info({ pro_no: videoData.pro_no });
    console.log("모달안에 어떤 친구들이?", result);

    if (result && result.rsp_code === "100") {
      setVideoUrl(encodeURI(result.mv_url));
      setImgFile1(result.file_nm1 ? encodeURI(result.file_nm1) : null);
      setImgFile2(result.file_nm2 ? encodeURI(result.file_nm2) : null);
      setImgFile3(result.file_nm3 ? encodeURI(result.file_nm3) : null);
      setImgFile4(result.file_nm4 ? encodeURI(result.file_nm4) : null);
      setManagerPhone(result.info);
    } else {
      MyUtil._alertMsg("m_mng_pro_info", videoData);
    }
  };

  const closeModal = () => {
    setIsInfoModal(false);
  };

  // 모달 내부 데이터 확인
  // useEffect(() => {
  //   m_mng_pro_info();
  // }, []);

  // const m_mng_pro_info = useCallback(async () => {
  //   let result = await ServerApi.m_mng_pro_info({ pro_no: videoData.pro_no });
  //   console.log("모달안에 어떤 친구들이?", result);
  //   if (result && result.rsp_code === "100") {
  //     setVideoUrl(encodeURI(result.mv_url));

  //     setImgFile1(result.file_nm1 ? encodeURI(result.file_nm1) : null);
  //     setImgFile2(result.file_nm2 ? encodeURI(result.file_nm2) : null);
  //     setImgFile3(result.file_nm3 ? encodeURI(result.file_nm3) : null);
  //     setImgFile4(result.file_nm4 ? encodeURI(result.file_nm4) : null);

  //     setManagerPhone(result.info);
  //   } else {
  //     MyUtil._alertMsg("m_mng_pro_info", videoData);
  //   }
  // }, []);

  const m_mng_pro_info_i = useCallback(async () => {
    const nowDate = new Date();
    let imgName1 = "",
      imgName2 = "",
      imgName3 = "",
      imgName4 = "";
    let videoName1 = "";

    try {
      if (!MyUtil._isNull(DELimgUrl1)) {
        const arrVideoUrl = DELimgUrl1.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName1 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl2)) {
        const arrVideoUrl = DELimgUrl2.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName2 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl3)) {
        const arrVideoUrl = DELimgUrl3.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName3 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl4)) {
        const arrVideoUrl = DELimgUrl4.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName4 = "-1";
      }

      if (!MyUtil._isNull(DELVideoUrl1)) {
        const arrVideoUrl = DELVideoUrl1.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        videoName1 = "-1";
      }

      if (!MyUtil._isNull(imgFile1?.name)) {
        imgName1 = "info/" + nowDate.getTime() + "_" + imgFile1?.name;
        const putParams = {
          Body: imgFile1,
          Bucket: Config.S3_BUCKET,
          Key: imgName1,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile2?.name)) {
        imgName2 = "info/" + nowDate.getTime() + "_" + imgFile2?.name;
        const putParams = {
          Body: imgFile2,
          Bucket: Config.S3_BUCKET,
          Key: imgName2,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile3?.name)) {
        imgName3 = "info/" + nowDate.getTime() + "_" + imgFile3?.name;
        const putParams = {
          Body: imgFile3,
          Bucket: Config.S3_BUCKET,
          Key: imgName3,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile4?.name)) {
        imgName4 = "info/" + nowDate.getTime() + "_" + imgFile4?.name;
        const putParams = {
          Body: imgFile4,
          Bucket: Config.S3_BUCKET,
          Key: imgName4,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(ADDvideoFile?.name)) {
        videoName1 = "info/" + nowDate.getTime() + "_" + ADDvideoFile?.name;
        const videoParams = {
          Body: ADDvideoFile,
          Bucket: Config.S3_BUCKET,
          Key: videoName1,
        };
        const putObj01: any = await putObjectWrapper(videoParams);
      }

      let result: any = await ServerApi.m_mng_pro_info_i({
        pro_no: videoData.pro_no,
        info: managerPhone,
        file_nm1: MyUtil._isNull(imgName1)
          ? ""
          : imgName1 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName1,
        file_nm2: MyUtil._isNull(imgName2)
          ? ""
          : imgName2 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName2,
        file_nm3: MyUtil._isNull(imgName3)
          ? ""
          : imgName3 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName3,
        file_nm4: MyUtil._isNull(imgName4)
          ? ""
          : imgName4 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName4,
        mv_url: MyUtil._isNull(videoName1)
          ? ""
          : videoName1 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + videoName1,
      });
      setDnStatus("loading");
      if (result && result.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            setIsInfoModal(false);
            setDnStatus("ready");
          }, 500);
        }, 1000);
      } else {
        console.log("안되");
      }
    } catch (err) {
      console.log("에러");
    }
  }, [
    imgFile1,
    imgFile2,
    imgFile3,
    imgFile4,
    ADDvideoFile,
    DELimgUrl1,
    DELimgUrl2,
    DELimgUrl3,
    DELimgUrl4,
    managerPhone,
    DELVideoUrl1,
  ]);

  const putObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket
        .putObject(params)
        .on("success", (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        });
    });
  };

  const deleteObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket
        .deleteObject(params)
        .on("success", (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        });
    });
  };

  return (
    <>
      <Items>
        {isEditMode && (
          <CheckBox
            type="checkbox"
            checked={isSelected}
            onChange={selectedItems}
          />
        )}
        <ItemsTitle onClick={handleItemClick}>{videoData.title}</ItemsTitle>
        {/*옵션 박스*/}
        <OptionControlBoxM
          options={["heart", "copy", "infoA", "setting", "list", "calendar"]}
          handleListClick={handleListClick}
          onCopyClick={handleCopyClick}
          onSettingClick={handleSettingClick}
          onChatClick={handleChatClick}
          handleInfoAClick={handleEditClick}
          handleCalendarClick={() => toggleCalendarIcon()}
        />
      </Items>
      {isCalendarOpen && (
        <CalendarBackdrop>
          <GlobalStyle />
          <CalendarWrap
            className={isCalendarOpen ? "visible" : ""}
            onClick={stopPropagation}
            {...swipeHandlers}
          >
            <CloseButton onClick={closeCalendarModal}>
              <IoIosCloseCircle />
            </CloseButton>
            <Calendar
              onChange={handleDateChange}
              value={viewDate}
              activeStartDate={activeStartDate}
              onActiveStartDateChange={({ activeStartDate }) => {
                if (activeStartDate) {
                  setActiveStartDate(activeStartDate);
                }
              }}
              formatDay={(locale, date) => moment(date).format("DD")}
              minDetail="month"
              maxDetail="month"
              next2Label={null}
              prev2Label={null}
              formatShortWeekday={(locale, date) =>
                customWeekdayLabels[date.getDay()]
              }
            />
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={dnStatus}
                setDnStatus={setDnStatus}
                myClick={handleSaveConfirm}
              />
            </ButtonWrap>
          </CalendarWrap>
        </CalendarBackdrop>
      )}
      {isModalOpen && (
        <VideoListModal
          videos={videoData.in_array.map((v: any) => ({
            url: v.mv_url,
            mv_nm: v.mv_nm,
            mv_no: v.mv_no,
            mv_rep_img: v.mv_rep_img,
          }))}
          onClose={() => setIsModalOpen(false)}
          title={videoData.title}
        />
      )}
      {isCommentModalOpen && (
        <StyledModal
          isOpen={isCommentModalOpen}
          onRequestClose={closeCommentModal}
          contentLabel="Comment Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <ModalTitle>COMMENT</ModalTitle>
          <textarea
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
            style={{
              width: "80%",
              height: "100px",
              margin: "0 auto 20px auto",
              display: "block",
              backgroundColor: "white",
              borderWidth: "1px",
              borderColor: "#000",
              borderStyle: "solid",
              resize: "none",
            }}
          />
          <NewSaveButton
            text={"SAVE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={handleSaveComment}
          />
        </StyledModal>
      )}
      {modalIsOpen && (
        <AlertModal
          isOpen={modalIsOpen}
          onRequestClose={closeAlertModal}
          contentLabel="Alert Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <h2>{modalMessage}</h2>
          <NewSaveButton
            text={"OK"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={closeAlertModal}
          />
        </AlertModal>
      )}

      {isInfoModal && (
        <InfoModal
          isOpen={isInfoModal}
          onRequestClose={closeModal}
          managerPhone={managerPhone}
          setManagerPhone={setManagerPhone}
          videoUrl={videoUrl}
          imgFiles={[imgFile1, imgFile2, imgFile3, imgFile4]}
          videoFile={videoFile}
          setImgFile1={setImgFile1}
          setImgFile2={setImgFile2}
          setImgFile3={setImgFile3}
          setImgFile4={setImgFile4}
          setVideoFile={setVideoFile}
          setDelImgUrl1={setDelImgUrl1} // 첫 번째 이미지 삭제 URL 설정 함수 전달
          setDelImgUrl2={setDelImgUrl2} // 두 번째 이미지 삭제 URL 설정 함수 전달
          setDelImgUrl3={setDelImgUrl3} // 세 번째 이미지 삭제 URL 설정 함수 전달
          setDelImgUrl4={setDelImgUrl4} // 네 번째 이미지 삭제 URL 설정 함수 전달
          setADDVideoFile={setADDVideoFile}
          m_mng_pro_info_i={m_mng_pro_info_i}
          dnStatus={dnStatus}
          setDnStatus={setDnStatus}
        />
      )}
    </>
  );
}

export default ExerciseSetup;
