import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import OptionControlBoxM from "./OptionControlBoxM";
import { useHistory } from "react-router-dom";
import VideoListModal from "./VideoListModal";
import * as ServerApi from "../../constants/ServerApiM";
import * as MyUtil from "../../constants/MyUtil";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";
import Modal from "react-modal";
import AWS from "aws-sdk";
import Config from "../../constants/Config";
import icTrash from "../../img/ic_trash.png";
import BannerItem2 from "../../component/BannerItem";
import AlertModal from "react-modal";
import NewSaveButton from "./NewSaveButton";

AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_AWSAccessKeyId}`,
  secretAccessKey: `${process.env.REACT_APP_AWSSecretKey}`,
});

const myBucket = new AWS.S3({
  params: { Bucket: Config.S3_BUCKET },
  region: Config.S3_REGION,
});

const Items = styled.div`
  flex: 0 1 calc(50% - 20px);
  overflow: hidden;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #09348a;
  border-radius: 10px;
  height: 100px;
  position: relative;
  color: #09348a;
  font-size: 30px;
`;

const ItemsTitle = styled.div`
  font-size: 0.9rem;
  margin-top: 30px;
`;

const SubmitButton = styled.div`
  margin: 20px auto 0 auto;
  width: 100px;
  height: 30px;
  border: 2px solid #09348a;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09348a;
  font-weight: 500;
`;

const ExitButton = styled.div`
  margin: 40px auto 0 auto;
  width: 100px;
  height: 30px;
  border: 2px solid #09348a;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09348a;
  font-weight: 500;
`;

const ModalTextBox = styled.textarea`
  border: none;
  width: 100%;
  height: 80px;
  font-size: 0.8rem;
  color: #000000;
  padding: 10px 0 0 10px;
  resize: none;
  font-family: "NanumSquare", sans-serif;

  &::placeholder {
    font-family: "Suit", serif;
  }

  :focus {
    outline: none;
  }
`;

const CommmentTextBox = styled.textarea`
  border: none;
  width: 80%;
  height: 80px;
  font-size: 0.8rem;
  color: #000000;
  padding: 10px 0 0 10px;
  resize: none;
  display: flex;
  justify-content: center;
  margin: 0 auto 15px auto;

  :focus {
    outline: none;
  }
`;

const ModalTitle = styled.h3`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  margin: 0 auto 20px auto;
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

function ProgramsItemContents({
  videoData,
  fetchVideos,
  selectedDate,
  fetchAndInitCalendar,
  DailySetup,
  centerProNo,
  videos,
}: any) {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const history = useHistory();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);
  const [isInfoModal, setIsInfoModal] = useState(false);

  // 인포메이션 모달
  const [isEditMode, setIsEditMode] = useState(false);
  const [managerPhone, setManagerPhone] = useState("");
  const [videoFile, setVideoFile] = useState<any>(null);
  const [imgFile1, setImgFile1] = useState<any>(null);
  const [imgFile2, setImgFile2] = useState<any>(null);
  const [imgFile3, setImgFile3] = useState<any>(null);
  const [imgFile4, setImgFile4] = useState<any>(null);
  const [videoUrl, setVideoUrl] = useState<any>("");
  const [DELVideoUrl1, setDelVideoUrl1] = useState<any>("");
  const [DELimgUrl1, setDelImgUrl1] = useState<any>("");
  const [DELimgUrl2, setDelImgUrl2] = useState<any>("");
  const [DELimgUrl3, setDelImgUrl3] = useState<any>("");
  const [DELimgUrl4, setDelImgUrl4] = useState<any>("");
  const [ADDvideoFile, setADDVideoFile] = useState<any>(null);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const putObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket
        .putObject(params)
        .on("success", (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        });
    });
  };

  const deleteObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket
        .deleteObject(params)
        .on("success", (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        });
    });
  };

  // 인포메이션 모달 끝

  // 클릭 이벤트를 이용하여 달력 창을 닫기 위한 로직
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarWrapRef.current &&
        !calendarWrapRef.current.contains(event.target as Node) &&
        isCalendarVisible
      ) {
        setIsCalendarVisible(false);
      }
    };

    // 컴포넌트가 마운트되면 document 클릭 이벤트 리스너를 추가하고, 언마운트 시에 제거
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCalendarVisible]);

  const handleItemClick = () => {
    history.push({
      pathname: "/VideoPlay",
      state: { pro_no: videoData.pro_no },
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleListClick = () => {
    setIsModalOpen(true);
  };

  const handleCopyVideoData = async () => {
    try {
      const response = await ServerApi.m_app_pro_center_i({
        c_day: selectedDate,
        pro_no: videoData.pro_no,
        center_no: MLoginInfo.MLoginInfo.center_no,
      });

      if (response.rsp_code === "100") {
        console.log("복제 성공:", response);
        fetchVideos(selectedDate);
      } else {
        console.error("복제 실패:", response.msg);
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  // 복사 버튼 핸들러
  const handleCopyClick = () => {
    handleCopyVideoData(); // 영상 데이터 복사 함수 호출
  };

  // 삭제 버튼 핸들러
  const handleDeleteClick = async () => {
    console.log("Data", videos, centerProNo);
    try {
      const data = {
        center_pro_no: centerProNo,
      };
      console.log("무슨 center_pro_no?", centerProNo);
      const response = await ServerApi.m_app_pro_center_d(data);
      console.log("삭제", response);
      if (response.rsp_code === "100") {
        console.log("삭제 성공", response, selectedDate);
        fetchVideos(selectedDate);
      } else {
        console.error("삭제 실패:", response.msg);
      }
    } catch (error) {
      console.error("삭제 중 오류 발생:", error);
    }
  };

  // 모달 내부 데이터 확인
  // useEffect(() => {
  //   m_mng_pro_info();
  // }, []);

  // const m_mng_pro_info = useCallback(async () => {
  //   let result = await ServerApi.m_mng_pro_info({ pro_no: videoData.pro_no });
  //   console.log("모달안에 어떤 친구들이?", result);
  //   if (result && result.rsp_code === "100") {
  //     setVideoUrl(encodeURI(result.mv_url));

  //     setImgFile1(result.file_nm1 ? encodeURI(result.file_nm1) : null);
  //     setImgFile2(result.file_nm2 ? encodeURI(result.file_nm2) : null);
  //     setImgFile3(result.file_nm3 ? encodeURI(result.file_nm3) : null);
  //     setImgFile4(result.file_nm4 ? encodeURI(result.file_nm4) : null);

  //     setManagerPhone(result.info);
  //   } else {
  //     MyUtil._alertMsg("m_mng_pro_info", videoData);
  //   }
  // }, []);

  // 인포메이션 모달 수정모드
  const m_mng_pro_info_i = useCallback(async () => {
    const nowDate = new Date();
    let imgName1 = "",
      imgName2 = "",
      imgName3 = "",
      imgName4 = "";
    let videoName1 = "";

    try {
      if (!MyUtil._isNull(DELimgUrl1)) {
        const arrVideoUrl = DELimgUrl1.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName1 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl2)) {
        const arrVideoUrl = DELimgUrl2.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName2 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl3)) {
        const arrVideoUrl = DELimgUrl3.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName3 = "-1";
      }

      if (!MyUtil._isNull(DELimgUrl4)) {
        const arrVideoUrl = DELimgUrl4.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        imgName4 = "-1";
      }

      if (!MyUtil._isNull(DELVideoUrl1)) {
        const arrVideoUrl = DELVideoUrl1.split("/");
        const videoNameDEL =
          arrVideoUrl[arrVideoUrl.length - 2] +
          "/" +
          arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoNameDEL };
        const result: any = await deleteObjectWrapper(videoParams);
        videoName1 = "-1";
      }

      if (!MyUtil._isNull(imgFile1?.name)) {
        imgName1 = "info/" + nowDate.getTime() + "_" + imgFile1?.name;
        const putParams = {
          Body: imgFile1,
          Bucket: Config.S3_BUCKET,
          Key: imgName1,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile2?.name)) {
        imgName2 = "info/" + nowDate.getTime() + "_" + imgFile2?.name;
        const putParams = {
          Body: imgFile2,
          Bucket: Config.S3_BUCKET,
          Key: imgName2,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile3?.name)) {
        imgName3 = "info/" + nowDate.getTime() + "_" + imgFile3?.name;
        const putParams = {
          Body: imgFile3,
          Bucket: Config.S3_BUCKET,
          Key: imgName3,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(imgFile4?.name)) {
        imgName4 = "info/" + nowDate.getTime() + "_" + imgFile4?.name;
        const putParams = {
          Body: imgFile4,
          Bucket: Config.S3_BUCKET,
          Key: imgName4,
        };
        const result: any = await putObjectWrapper(putParams);
      }

      if (!MyUtil._isNull(ADDvideoFile?.name)) {
        videoName1 = "info/" + nowDate.getTime() + "_" + ADDvideoFile?.name;
        const videoParams = {
          Body: ADDvideoFile,
          Bucket: Config.S3_BUCKET,
          Key: videoName1,
        };
        const putObj01: any = await putObjectWrapper(videoParams);
      }

      let result: any = await ServerApi.m_mng_pro_info_i({
        pro_no: videoData.pro_no,
        info: managerPhone,
        file_nm1: MyUtil._isNull(imgName1)
          ? ""
          : imgName1 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName1,
        file_nm2: MyUtil._isNull(imgName2)
          ? ""
          : imgName2 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName2,
        file_nm3: MyUtil._isNull(imgName3)
          ? ""
          : imgName3 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName3,
        file_nm4: MyUtil._isNull(imgName4)
          ? ""
          : imgName4 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + imgName4,
        mv_url: MyUtil._isNull(videoName1)
          ? ""
          : videoName1 === "-1"
          ? "-1"
          : process.env.REACT_APP_AWS_S3_URL + videoName1,
      });
      setDnStatus("loading");
      if (result && result.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          setTimeout(() => {
            setIsInfoModal(false);
            setDnStatus("ready");
          }, 500);
        }, 1000);
      } else {
        console.log("안되");
      }
    } catch (err) {
      console.log("에러");
    }
  }, [
    imgFile1,
    imgFile2,
    imgFile3,
    imgFile4,
    ADDvideoFile,
    DELimgUrl1,
    DELimgUrl2,
    DELimgUrl3,
    DELimgUrl4,
    managerPhone,
    DELVideoUrl1,
  ]);

  // 수정 모드로 전환하는 함수
  // const handleEditClick = () => {
  //   console.log("수정 모드로 전환");
  //   setIsEditMode(true);
  //   setIsInfoModal(true); // 정보 모달 열기
  // };
  const handleEditClick = async () => {
    console.log("수정 모드");
    setIsInfoModal(true);

    // 버튼 클릭 시 데이터 호출
    let result = await ServerApi.m_mng_pro_info({ pro_no: videoData.pro_no });
    console.log("모달안에 어떤 친구들이?", result);

    if (result && result.rsp_code === "100") {
      setVideoUrl(encodeURI(result.mv_url));
      setImgFile1(result.file_nm1 ? encodeURI(result.file_nm1) : null);
      setImgFile2(result.file_nm2 ? encodeURI(result.file_nm2) : null);
      setImgFile3(result.file_nm3 ? encodeURI(result.file_nm3) : null);
      setImgFile4(result.file_nm4 ? encodeURI(result.file_nm4) : null);
      setManagerPhone(result.info);
    } else {
      MyUtil._alertMsg("m_mng_pro_info", videoData);
    }
  };

  // 보기 모드로 전환하는 함수
  const handleViewClick = async () => {
    console.log("보기 모드");
    setIsEditMode(false);
    setIsInfoModal(true); // 정보 모달 열기

    // 버튼 클릭 시 데이터 호출
    let result = await ServerApi.m_mng_pro_info({ pro_no: videoData.pro_no });
    console.log("모달안에 어떤 친구들이?", result);

    if (result && result.rsp_code === "100") {
      setVideoUrl(encodeURI(result.mv_url));
      setImgFile1(result.file_nm1 ? encodeURI(result.file_nm1) : null);
      setImgFile2(result.file_nm2 ? encodeURI(result.file_nm2) : null);
      setImgFile3(result.file_nm3 ? encodeURI(result.file_nm3) : null);
      setImgFile4(result.file_nm4 ? encodeURI(result.file_nm4) : null);
      setManagerPhone(result.info);
    } else {
      MyUtil._alertMsg("m_mng_pro_info", videoData);
    }
  };

  const closeModal = () => {
    setIsInfoModal(false);
  };

  const handleChatClick = () => {
    setIsCommentModalOpen(true);
  };

  // 댓글 저장 함수
  const handleSaveComment = async () => {
    if (!commentContent.trim()) {
      setIsCommentModalOpen(false);
      setModalMessage("댓글 내용을 입력해주세요.");
      setModalIsOpen(true);
      return;
    }

    try {
      const response = await ServerApi.m_mng_pro_reply_i({
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no: videoData.pro_no,
        contents: commentContent,
      });

      if (response && response.rsp_code === "100") {
        setIsCommentModalOpen(false);
        setCommentContent("");
        setModalMessage("댓글이 성공적으로 등록되었습니다.");
        setModalIsOpen(true);
      } else {
        setModalMessage("댓글등록에 실패했습니다.");
        setModalIsOpen(true);
        setIsCommentModalOpen(false);
        setCommentContent("");
      }
    } catch (error) {
      console.error("댓글 등록 중 오류 발생:", error);
      setModalMessage("댓글 등록 중 오류가 발생했습니다.");
      setModalIsOpen(true);
      setIsCommentModalOpen(false);
      setCommentContent("");
    }
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  const closeCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  const handleRemoteClick = () => {
    history.push({
      pathname: "/NumberPad",
      state: { pro_no: videoData.pro_no },
    });
  };

  return (
    <>
      {modalIsOpen && (
        <AlertModal
          isOpen={modalIsOpen}
          onRequestClose={closeAlertModal}
          contentLabel="Alert Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "99",
            },
          }}
        >
          <h2>{modalMessage}</h2>
          <NewSaveButton
            text={"OK"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={closeAlertModal}
          />
        </AlertModal>
      )}

      {isCommentModalOpen && (
        <AlertModal
          isOpen={isCommentModalOpen}
          onRequestClose={closeCommentModal}
          contentLabel="Comment Modal"
          className="modalAlertBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "999",
            },
          }}
        >
          <ModalTitle>COMMENT</ModalTitle>
          <CommmentTextBox
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
          />
          <NewSaveButton
            text={"SAVE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={handleSaveComment}
          />
        </AlertModal>
      )}
      <Items>
        <ItemsTitle onClick={handleItemClick}>{videoData.title}</ItemsTitle>
        {/*옵션 박스*/}
        <OptionControlBoxM
          options={
            DailySetup
              ? ["heart", "copy", "chat", "infoA", "list", "trash"]
              : ["heart", "infoB", "list", "remote"]
          }
          handleListClick={handleListClick}
          onCopyClick={handleCopyClick}
          onDeleteClick={handleDeleteClick}
          onChatClick={handleChatClick}
          onRemoteClick={handleRemoteClick}
          handleInfoAClick={handleEditClick}
          handleInfoBClick={handleViewClick}
          // {...(DailySetup
          //   ? { handleInfoAClick: handleEditClick }
          //   : { handleInfoBClick: handleViewClick })}
        />
      </Items>
      {isModalOpen && (
        <VideoListModal
          videos={videoData.in_array.map((v: any) => ({
            url: v.mv_url,
            mv_nm: v.mv_nm,
            mv_no: v.mv_no,
            mv_rep_img: v.mv_rep_img,
          }))}
          title={videoData.title}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {isInfoModal && isEditMode ? (
        <Modal
          isOpen={isInfoModal}
          onRequestClose={closeModal}
          contentLabel="Information Modal"
          className="informationBox"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: "999",
            },
          }}
        >
          <div
            className="modalIpWrap"
            style={{ borderBottomWidth: "0px", marginTop: "-50px" }}
          >
            <span className="modalIpTitleAdmin">Information</span>
          </div>
          <div className="modalIpWrap" style={{ marginTop: "-10px" }}>
            <ModalTextBox
              required
              placeholder="Please enter the comment"
              value={managerPhone}
              onChange={(evt) => setManagerPhone(evt.target.value)}
            ></ModalTextBox>
          </div>

          <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
            <span className="modalIpTitleAdmin">Video</span>
          </div>
          <div className="modalIpWrap" style={{ marginTop: "-5px" }}>
            <input
              style={{ display: "none" }}
              type="file"
              id="ex_file"
              accept={"video/mp4,video/mkv, video/x-m4v,video/*"}
              placeholder={"비디오 선택"}
              onChange={(event: any) => {
                setVideoFile(event.target.files[0]);
                setADDVideoFile(event.target.files[0]);
              }}
              multiple={false}
            />
            <label htmlFor="ex_file" className="fileBtn">
              FILE +
            </label>
            <span className="fileBtnSub">
              {videoFile?.name
                ? videoFile?.name
                : videoUrl
                ? videoUrl
                : "Please select a file"}
            </span>

            <img
              style={{
                width: "22px",
                height: "22px",
                marginLeft: "6px",
                cursor: "pointer",
                marginRight: "8px",
              }}
              src={icTrash}
              alt="logo image"
              onClick={() => {
                setVideoFile(null);
                setADDVideoFile(null);
                const tmep = videoUrl;
                setDelVideoUrl1(tmep);
                setVideoUrl("");
              }}
            />
          </div>

          <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
            <span className="modalIpTitleAdmin">Image</span>
          </div>

          <div
            style={{
              marginTop: "-20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <BannerItem2
              item={{ url: "", uid: "", anchor: "" }}
              width={"50px"}
              height={"50px"}
              marginTop={"0px"}
              setImgFile={setImgFile1}
              defaultImg={imgFile1}
              setDelImgUrl={setDelImgUrl1}
            />

            <BannerItem2
              item={{ url: "", uid: "", anchor: "" }}
              width={"50px"}
              height={"50px"}
              marginTop={"0px"}
              setImgFile={setImgFile2}
              defaultImg={imgFile2}
              setDelImgUrl={setDelImgUrl2}
            />

            <BannerItem2
              item={{ url: "", uid: "", anchor: "" }}
              width={"50px"}
              height={"50px"}
              marginTop={"0px"}
              setImgFile={setImgFile3}
              defaultImg={imgFile3}
              setDelImgUrl={setDelImgUrl3}
            />

            <BannerItem2
              item={{ url: "", uid: "", anchor: "" }}
              width={"50px"}
              height={"50px"}
              marginTop={"0px"}
              setImgFile={setImgFile4}
              defaultImg={imgFile4}
              setDelImgUrl={setDelImgUrl4}
            />
          </div>
          <ButtonWrap>
            <NewSaveButton
              text={"SAVE"}
              dnStatus={dnStatus}
              setDnStatus={setDnStatus}
              myClick={() => {
                m_mng_pro_info_i();
              }}
            />
          </ButtonWrap>
        </Modal>
      ) : (
        <Modal
          isOpen={isInfoModal}
          onRequestClose={closeModal}
          contentLabel="Information Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.55)",
              zIndex: "99999",
              overflowY: "auto",
            },
          }}
        >
          <div>
            <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
              <span className="modalIpTitleAdmin">Information</span>
            </div>
            <div className="modalIpWrap" style={{ marginTop: "-10px" }}>
              <p className="informationContents">
                {managerPhone ? managerPhone : ""}
              </p>
            </div>

            <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
              <span className="modalIpTitleAdmin">Video</span>
            </div>
            <div className="modalIpWrap" style={{ marginTop: "-5px" }}>
              {videoUrl && (
                <div style={{ marginBottom: "20px" }}>
                  <video width="100%" height="auto" autoPlay>
                    <source src={videoUrl} type="video/mp4" />
                    죄송합니다, 귀하의 브라우저는 비디오 태그를 지원하지
                    않습니다.
                  </video>
                </div>
              )}
            </div>

            <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
              <span className="modalIpTitleAdmin">Image</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {imgFile1 && (
                <img
                  src={imgFile1}
                  alt="Image 1"
                  style={{ width: "120px", height: "120px" }}
                />
              )}
              {imgFile2 && (
                <img
                  src={imgFile2}
                  alt="Image 2"
                  style={{ width: "120px", height: "120px" }}
                />
              )}
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {imgFile3 && (
                <img
                  src={imgFile3}
                  alt="Image 3"
                  style={{ width: "120px", height: "120px" }}
                />
              )}
              {imgFile4 && (
                <img
                  src={imgFile4}
                  alt="Image 4"
                  style={{ width: "120px", height: "120px" }}
                />
              )}
            </div>
            <ButtonWrap>
              <NewSaveButton
                text={"EXIT"}
                dnStatus={"ready"}
                setDnStatus={""}
                myClick={closeModal}
              />
            </ButtonWrap>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ProgramsItemContents;
